import {Vector3} from "three"
import App from "../../App.js"
import EventEmitter from "../utils/EventEmitter.js"

export default class Hotspots extends EventEmitter{
    constructor()
    {
        super()

        this.app = new App()
        this.sizes = this.app.sizes
        this.camera = this.app.camera
        this.raycaster = this.app.raycaster

        this.initializePoints()
        this.setSidebarAnimation()
        this.setHelpAnimation()
    }

    initializePoints() {
        this.points = [
            {
                position: new Vector3(48, -18, -50),
                element: document.querySelector('.point-0'),
                sidebar: document.querySelector('.side-1'),
                container: document.querySelector('.contain-1')
            },
            {
                position: new Vector3(18, 22, -22),
                element: document.querySelector('.point-1'),
                sidebar: document.querySelector('.side-2'),
                container: document.querySelector('.contain-2')
            },
            {
                position: new Vector3(-6, 62, 23),
                element: document.querySelector('.point-2'),
                sidebar: document.querySelector('.side-3'),
                container: document.querySelector('.contain-3')
            }
        ]
        
        this.closeButtons = document.querySelectorAll('.desc-close')
        this.closeHelp = document.querySelector('.help-close')
        this.openHelp = document.getElementById("help-menu")
        this.help = document.querySelector('.help-window')

        this.sidebars = document.querySelectorAll('.sidebar-right')
    }

    setSidebarAnimation() {
        this.clicked = [ false, false, false ]

        // Show/Hide Sidebars with Hotspot Buttons
        this.points[0].element.addEventListener("click", (e) => {
            if(!this.clicked[0])
            {
                this.points[1].sidebar.classList.remove('visible')
                this.points[2].sidebar.classList.remove('visible')
                this.points[0].sidebar.classList.add('visible')

                this.points[1].container.classList.remove('visible')
                this.points[2].container.classList.remove('visible')
                this.points[0].container.classList.add('visible')

                this.clicked = [ true, false, false ]

                this.trigger('hotspotFocused', [80.18, -24.21, -60.64])
            }
            else {
                this.trigger('hotspotUnfocused')
                this.points[0].sidebar.classList.remove('visible')
                this.points[0].container.classList.remove('visible')
                this.clicked[0] = false
            }
        })

        this.points[1].element.addEventListener("click", (e) => {
            if(!this.clicked[1])
            {
                this.points[0].sidebar.classList.remove('visible')
                this.points[2].sidebar.classList.remove('visible')
                this.points[1].sidebar.classList.add('visible')

                this.points[0].container.classList.remove('visible')
                this.points[2].container.classList.remove('visible')
                this.points[1].container.classList.add('visible')
                
                this.clicked = [ false, true, false ]

                this.trigger('hotspotFocused', [35.22, 30.13, -30.93])
            }
            else {
                this.trigger('hotspotUnfocused')
                this.points[1].sidebar.classList.remove('visible')
                this.points[1].container.classList.remove('visible')
                this.clicked[1] = false
            }
        })

        this.points[2].element.addEventListener("click", (e) => {
            if(!this.clicked[2])
            {
                this.points[0].sidebar.classList.remove('visible')
                this.points[1].sidebar.classList.remove('visible')
                this.points[2].sidebar.classList.add('visible')

                this.points[0].container.classList.remove('visible')
                this.points[1].container.classList.remove('visible')
                this.points[2].container.classList.add('visible')
                
                this.clicked = [ false, false, true ]

                this.trigger('hotspotFocused', [76.02, 94.51, -53.68])
            }
            else {
                this.trigger('hotspotUnfocused')
                this.points[2].sidebar.classList.remove('visible')
                this.points[2].container.classList.remove('visible')
                this.clicked[2] = false
            }
        })

        // Close Button Event
        for(const close of this.closeButtons) {
            close.addEventListener("click", (e) => {
                this.points[0].sidebar.classList.remove('visible')
                this.points[1].sidebar.classList.remove('visible')
                this.points[2].sidebar.classList.remove('visible')
                this.points[0].container.classList.remove('visible')
                this.points[1].container.classList.remove('visible')
                this.points[2].container.classList.remove('visible')

                this.trigger('hotspotUnfocused')
                this.clicked = [ false, false, false ]
            })
        }
    }

    setHelpAnimation() {
        this.openHelp.addEventListener("click", (e) => {
            for(const point of this.points)
            {
                point.element.classList.add('disable')
            }
            this.help.style.display = "block"
            window.setTimeout(() => {
                this.help.classList.add('visible')
            }, 50)
        })
        this.closeHelp.addEventListener("click", (e) => {
            for(const point of this.points)
            {
                point.element.classList.remove('disable')
            }
            this.help.classList.remove('visible')
            window.setTimeout(() => {
                this.help.style.display = "none"
            }, 400)
        })
        this.openHelp.addEventListener("touchstart", (e) => {
            for(const point of this.points)
            {
                point.element.classList.add('disable')
            }
            this.help.style.display = "block"
            window.setTimeout(() => {
                this.help.classList.add('visible')
            }, 50)
        })
        this.closeHelp.addEventListener("touchstart", (e) => {
            for(const point of this.points)
            {
                point.element.classList.remove('disable')
            }
            this.help.classList.remove('visible')
            window.setTimeout(() => {
                this.help.style.display = "none"
            }, 400)
        })

        for(const point of this.points)
        {
            point.element.classList.add('disable')
        }
        this.help.style.display = "block"
        window.setTimeout(() => {
            this.help.classList.add('visible')
        }, 50)
    }

    update() {
        for(const point of this.points)
        {   
            // Animate hotspot position
            const screenPosition = point.position.clone()
            screenPosition.project(this.camera.instance)

            const translateX = screenPosition.x * this.sizes.width * 0.5
            const translateY = screenPosition.y * this.sizes.height * -0.5
            point.element.style.transform = `translate(${translateX}px, ${translateY}px)`

            // Show/Hide hotspot
            this.raycaster.instance.setFromCamera(screenPosition, this.camera.instance)
            const intersects = this.raycaster.instance.intersectObjects(this.raycaster.items, true)
            if(intersects.length === 0)
            {
                point.element.classList.add('visible')
            }
            else {
                const intersectDistance = intersects[0].distance
                const pointDistance = point.position.distanceTo(this.camera.instance.position)
                if(intersectDistance < pointDistance)
                {
                    point.element.classList.remove('visible')
                }
                else {
                    point.element.classList.add('visible')
                }
            }
        }
    }
}