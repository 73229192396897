import {Mesh, Scene} from 'three'
import MODS from './modules/Modules.js'
import resources from './modules/resources.js'

let instance = null

export default class App {
    constructor()
    {
        if(instance)
        {
            return instance
        }

        instance = this

        this.canvas = document.querySelector('canvas.webgl')
        this.scene = new Scene()
        this.sizes =    new MODS.Sizes()
        this.time =     new MODS.Time()
        this.loader =   new MODS.Loader(resources)

        this.camera =   new MODS.Camera()
        this.raycaster = new MODS.Raycaster()

        this.world = new MODS.World()
        
        this.renderer = new MODS.Renderer()

        this.sizes.on('resize', () => this.resize())
        this.time.on('tick', () => this.update())
        this.loader.on('ready', () => {
            this.world.hotspots.on('hotspotFocused', (x, y, z) => this.camera.onFocus(x, y, z))
            this.world.hotspots.on('hotspotUnfocused', () => this.camera.onUnfocused())
        })
    }

    resize() {
        this.world.update()
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy() {
        this.sizes.off('resize')
        this.time.off('tick')

        this.scene.traverse((child) => {
            if(child instanceof Mesh)
            {
                child.geometry.dispose()

                for(const key in child.material)
                {
                    const value = child.material[key]

                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()
    }

}