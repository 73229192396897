import App from '../../App.js'
import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter {
    constructor()
    {
        super()

        this.app = new App()
        this.canvas = this.app.canvas

        this.width = window.innerWidth
        this.height = window.innerHeight

        window.addEventListener('resize', () => this.onResize())
        window.addEventListener('keydown', (e) => this.onFullscreen(e))
    }

    onResize() {
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.trigger('resize')
    }

    onFullscreen(e)
    {
        const fullscreen = document.fullscreenElement || document.webkitFullscreenElement

        if(e.key === 'f')
        {
            if(!fullscreen && this.canvas.requestFullscreen)
            {
                this.canvas.requestFullscreen()
            }
            else if(document.exitFullscreen)
            {
                document.exitFullscreen()
            }
        }
    }
}