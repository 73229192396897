export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path: [
            '/textures/environmentMaps/1/px.jpg',
            '/textures/environmentMaps/1/nx.jpg',
            '/textures/environmentMaps/1/py.jpg',
            '/textures/environmentMaps/1/ny.jpg',
            '/textures/environmentMaps/1/pz.jpg',
            '/textures/environmentMaps/1/nz.jpg'
        ]
    },
    {
        name: 'modelB',
        type: 'gltfModel',
        path: '/models/knob.glb'
    }
]