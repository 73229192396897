import {WebGLRenderer, sRGBEncoding, ReinhardToneMapping, PCFSoftShadowMap} from 'three'
import App from '../App.js'

export default class Renderer {
    constructor()
    {
        this.app = new App()
        this.scene = this.app.scene
        this.canvas = this.app.canvas
        this.sizes = this.app.sizes
        this.camera = this.app.camera.instance

        this.setInstance()
    }

    setInstance() {
        this.instance = new WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        })

        this.instance.toneMappingExposure = 3
        this.instance.outputEncoding = sRGBEncoding
        this.instance.toneMapping = ReinhardToneMapping
        this.instance.shadowMap.type = PCFSoftShadowMap
        this.instance.shadowMap.enabled = true
        this.instance.localClippingEnabled = true
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    resize() {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    update() {
        this.instance.render(this.scene, this.camera)
    }
}