import {Vector3} from 'three'
import App from '../../App.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Model from './Model.js'
import Hotspots from './Hotspots.js'

export default class World {
    constructor()
    {
        this.app = new App()
        this.scene = this.app.scene
        this.loader = this.app.loader
        this.resources = this.app.loader.items

        this.loader.on('ready', () => this.create())
    }

    create() {
        this.createEnvironment()
        this.createObjects()
        this.createHotspots()
        this.showHeader()
    }

    createEnvironment() {
        this.floor = new Floor()
        this.environment = new Environment()
    }

    createObjects() {
        this.model = new Model(
            this.resources.modelB,
            "CircuitGold",
            "CircuitSilver",
            "ClipPlaneGold",
            "ClipPlaneSilver",
            new Vector3(-1, 0, 0),
            new Vector3(-1, 0, 0),
            'Print'
            )
    }

    createHotspots() {
        this.hotspots = new Hotspots()
    }

    showHeader() {
        this.header = document.querySelector('header')
        this.header.style.display = "block"
    }

    update() {
        if(this.model)
        {
            this.model.update()
            this.hotspots.update()
        }
    }
}