import EventEmitter from './EventEmitter.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'
import * as THREE from 'three'
import App from '../../App.js'

export default class Loader extends EventEmitter {
    constructor(sources)
    {
        super()

        this.sources = sources
        this.app = new App()
        this.scene = this.app.scene

        this.items = {}
        this.progressRatio = 0

        this.loadingBar = document.querySelector('.loading-bar')

        this.createLoadingScreen()
        this.setManager()
        this.setLoaders()
        this.loadAll()
    }

    createLoadingScreen() {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            uniforms:
            {
                uAlpha: { value: 1 }
            },
            vertexShader: `
                void main()
                {
                    gl_Position = vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float uAlpha;
        
                void main()
                {
                    gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
                }
            `
        })

        this.overlay = new THREE.Mesh(
            new THREE.PlaneGeometry(2, 2),
            this.material
        )
        this.scene.add(this.overlay)
    }

    setManager()
    {
        this.manager = new THREE.LoadingManager(
            // onLoaded
            () => {
                window.setTimeout(() => {
                    this.loadingBar.classList.add('ended')
                    this.loadingBar.style.transform = ''
                    gsap.to(this.material.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
                }, 500)
                window.setTimeout(() => { this.trigger('ready') }, 200)
            },
            // onProgress
            (itemUrl, itemsLoaded, itemsTotal) => {
                this.progressRatio = itemsLoaded / itemsTotal
                this.loadingBar.style.transform = `scaleX(${this.progressRatio})`
            }
            
        )
    }

    setLoaders()
    { 
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader(this.manager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.manager)
    }

    loadAll()
    {
        for(const source of this.sources)
        {
            if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) => {
                        this.items[source.name] = file
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) => {
                        this.items[source.name] = file
                    }
                )
            }
        }
    }
}