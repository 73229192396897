import App from '../App.js'
import { PerspectiveCamera } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { gsap } from 'gsap'

export default class Camera {
    constructor()
    {
        this.app = new App()
        this.scene = this.app.scene
        this.canvas = this.app.canvas
        this.sizes = this.app.sizes

        this.setInstance()
        this.setControls()
    }

    setInstance() {
        this.instance = new PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.01, 1000)

        this.instance.position.set(140, 40, -20)
        this.scene.add(this.instance)
    }

    setControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enablePan = false
        this.controls.enableDamping = true
        this.controls.maxAzimuthAngle = Math.PI * 15/16
        this.controls.minAzimuthAngle = Math.PI * 1/16
        this.controls.maxPolarAngle = Math.PI * 9/16
        this.controls.minPolarAngle = Math.PI * 3/16
        this.controls.maxDistance = 175
        this.controls.minDistance = 55
    }

    onFocus(x, y, z) {
        this.controls.enabled = false
        gsap.to(this.instance.position, {x: x, y: y, z: z, duration: 1, delay: 0})
    }

    onUnfocused() {
        gsap.to(this.instance.position, {x: 140, y: 40, z: -20, duration: 1, delay: 0})
        this.controls.enabled = true
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update() {
        this.controls.update()
    }
}