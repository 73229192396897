import App from '../../App.js'
import * as THREE from 'three'

export default class Environment {
    constructor()
    {
        this.app = new App()
        this.scene = this.app.scene
        this.resources = this.app.loader.items

        this.backgroundColor = new THREE.Color(0x887ce2)
        this.fogColor = new THREE.Fog(0x887ce2, 80, 500)

        this.setLights()
        this.setReflections()
        this.setBackground()
    }

    setLights() {
        this.light = new THREE.DirectionalLight(0xffffff, 2)
        this.light.castShadow = true
        this.light.shadow.normalBias    =  0.05
        this.light.shadow.camera.near   =  0.1
        this.light.shadow.camera.far    =  4000
        this.light.shadow.camera.left   = -200
        this.light.shadow.camera.right  =  200
        this.light.shadow.camera.top    =  200
        this.light.shadow.camera.bottom = -200

        this.light.shadow.mapSize.set(1024, 1024)
        this.light.position.set(30, 100, 100)

        this.scene.add(this.light)
    }

    setReflections() {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.4
        this.environmentMap.texture = this.resources.environmentMapTexture
        this.environmentMap.texture.encoding =THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture

        this.updateMaterials()
    }

    setBackground() {
        this.scene.background = this.backgroundColor
        this.scene.fog = this.fogColor
    }

    updateMaterials() {
        this.scene.traverse((child) => {
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                child.material.envMap = this.environmentMap.texture
                child.material.envMapIntensity = this.environmentMap.intensity
                child.material.needsUpdate = true
            }
        })
    }
}