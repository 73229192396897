import {AnimationMixer, Mesh, Plane} from "three"
import App from "../../App.js"

export default class Model {
    constructor(gltfModel, draw1Name, draw2Name, plane1Name, plane2Name, plane1Normal, plane2Normal, animationName)
    {
        this.app = new App()
        this.scene = this.app.scene
        this.time = this.app.time
        this.raycasterItems = this.app.raycaster.items

        this.scale = 2.5

        this.draw1Name = draw1Name
        this.draw2Name = draw2Name
        this.plane1Name = plane1Name
        this.plane2Name = plane2Name
        this.plane1Normal = plane1Normal
        this.plane2Normal = plane2Normal
        this.animationName = animationName
        
        this.setModel(gltfModel)
        this.setBooleanConstraint()
        this.setAnimation()
    }

    setModel(gltfModel) {
        this.model = gltfModel
        this.model.scene.scale.set(this.scale, this.scale, this.scale)
        this.model.scene.rotation.y = Math.PI * 0.5
        this.scene.add(this.model.scene)

        this.scene.traverse((child) => {
            if(child instanceof Mesh)
            {
                child.castShadow = true
            }
            // child.frustumCulled = false
        })
    }

    setBooleanConstraint() {
        this.draw1 = null
        this.draw2 = null
        this.plane1 = null
        this.plane2 = null

        this.boolPlane1 = null
        this.boolPlane2 = null

        this.direction1 = 0
        this.direction2 = 0

        this.scene.traverse((child) => {
            if(child.name === this.draw1Name)
            {
                this.draw1 = child
            }
            else if(child.name === this.draw2Name)
            {
                this.draw2 = child
            }
            else if(child.name === this.plane1Name)
            {
                this.plane1 = child
                this.plane1.material.visible = false
                this.plane1.material.depthWrite = false
            }
            else if(child.name === this.plane2Name)
            {
                this.plane2 = child
                this.plane2.material.visible = false
                this.plane2.material.depthWrite = false
            }
            else if(child.name === "Panel_Back" || child.name === "Panel_L" || child.name === "Panel_R" ||
                child.name === "Panel_Front" || child.name === "Panel_Top" || child.name === "Panel_Bottom")
            {
                this.raycasterItems.push(child)
            }
            // console.log(child)
        })

        if(this.draw1 && this.draw2 && this.plane1 && this.plane2)
        {
            this.boolPlane1 = new Plane(this.plane1Normal, 0)
            this.boolPlane2 = new Plane(this.plane2Normal, 1)

            this.draw1.material.clippingPlanes = [ this.boolPlane1 ]
            this.draw2.material.clippingPlanes = [ this.boolPlane2 ]
        }
        else {
            console.log("Drawings and Boolean Planes not found.")
        }

        if(this.plane1Normal.x != 0)
        {
            this.direction1 = this.plane1.position.z
        }
        else if(this.plane1Normal.y != 0)
        {
            this.direction1 = this.plane1.position.y
            
        }
        else if(this.plane1Normal.z != 0)
        {
            this.direction1 = this.plane1.position.x
        }

        if(this.plane2Normal.x != 0)
        {
            this.direction2 = this.plane2.position.z
        }
        else if(this.plane2Normal.y != 0)
        {
            this.direction2 = this.plane2.position.y
        }
        else if(this.plane2Normal.z != 0)
        {
            this.direction2 = this.plane2.position.x
        }
    }

    setAnimation() {
        this.animation = {}
        this.animation.actions = new Map()
        this.animation.mixer = new AnimationMixer(this.model.scene)

        this.model.animations.forEach((action) => {
            this.animation.actions.set(action.name, this.animation.mixer.clipAction(action))
        })

        this.animation.play = this.animation.actions.get(this.animationName)
        this.animation.play.reset().play()
    }

    update() {
        this.animation.mixer.update(this.time.delta * 0.001)
        this.boolPlane1.constant = ((this.plane1Normal.x * -this.plane1.position.z) +
            (this.plane1Normal.y * this.plane1.position.y) + (this.plane1Normal.z * this.plane1.position.x)) 
            * this.scale
        this.boolPlane2.constant = ((this.plane2Normal.x * -this.plane2.position.z) +
            (this.plane2Normal.y * this.plane2.position.y) + (this.plane2Normal.z * this.plane2.position.x)) 
            * this.scale
    }
}