import {Mesh, MeshStandardMaterial, PlaneGeometry} from "three"
import App from "../../App.js"

export default class Floor {
    constructor()
    {
        this.app = new App()
        this.scene = this.app.scene
        this.width = 2000
        this.height = 2000
        this.color = 0x887ce2

        this.setInstance()
    }

    setInstance() {
        this.instance = new Mesh(
            new PlaneGeometry(this.width, this.height),
            new MeshStandardMaterial({color: this.color, depthWrite: false})
        )

        this.instance.receiveShadow = true
        this.instance.rotation.x = Math.PI / -2
        this.instance.position.y = -47

        this.scene.add(this.instance)
    }
}